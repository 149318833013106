import React from 'react';

import Language from 'src/config/Language';
import useRouter from 'src/shared/hooks/useRouter';
import Dropdown from 'src/shared/components/Dropdown';
import PlainButton from 'src/shared/components/PlainButton';
import { fireGA } from 'src/utils/gaUtils';

import styles from './styles.module.scss';

interface Props {
    type?: 'black' | 'white' | 'transparent';
    isInline?: boolean;
    className?: string;
    getAsPath?: (lang: string) => string;
}

const LanguageSwitcher = (props: Props) => {
    const router = useRouter();

    const onClickLang = (lang) => {
        fireGA('platform', 'clickSwitchLanguage', router.route);
        // Prevent from nextjs Link using cached css when reload the same path
        const asPath = props.getAsPath ? props.getAsPath(lang) : router.asPath;
        window.location.href = `/${lang}${asPath}`;
    };

    const items = Object.values(Language)
        .filter((lang) => !lang.hide)
        .map((lang) => ({
            text: lang.label,
            onClick: () => onClickLang(lang.path),
        }));

    if (props.isInline) {
        return (
            <div className={`${styles.inlineContainer} ${props.className}`}>
                {Object.entries(Language)
                    .filter(([, item]) => item.hide !== true)
                    .map(([lang, item]) => (
                        <PlainButton className={styles.button} key={lang} clear onClick={() => onClickLang(lang)}>
                            {item.label}
                        </PlainButton>
                    ))}
            </div>
        );
    }

    return (
        <Dropdown
            className={props.className}
            itemClassName={styles.dropdownItem}
            linkClassName={styles.dropdownLink}
            alignment="right"
            type={props.type}
            selected={Language[router.locale].label}
            text={Language[router.locale].label}
            itemWidth="100%"
            items={items}
            renderButton={() => (
                <>
                    {Language[router.locale].label}
                    <i className={`icon-arrow_solid-63 ${styles.icon}`} />
                </>
            )}
        />
    );
};

LanguageSwitcher.defaultProps = {
    type: 'black',
    className: '',
    isInline: false,
    getAsPath: null,
};

export default LanguageSwitcher;
