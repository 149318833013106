import { useEffect, useState } from 'react';
import AccountAPI from 'src/shared/services/api/AccountAPI';
import ssoUtils from 'src/shared/utils/ssoUtils';
import { useDispatch } from 'react-redux';
import userActions from 'src/shared/store/user/actions';
import moment from 'moment';

const validSecondRange = 20;
const isDev = process.env.DEV;

const useSSO = (sso: string) => {
    const [isReady, setIsReady] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const dispatch = useDispatch();

    const getProfile: () => void = async () => {
        let time = '';
        let token = '';
        const decryptedWords = ssoUtils.decrypt(sso);
        let isValidTime = false;
        if (!decryptedWords) {
            setIsInvalid(true);
            return;
        }
        const decryptedInfo = JSON.parse(decryptedWords);
        token = decryptedInfo.token;
        time = decryptedInfo.time;
        const duration = moment.duration(moment().diff(new Date(parseFloat(time))));
        isValidTime = isDev ? true : duration.asSeconds() <= validSecondRange;
        if (!isValidTime) {
            setIsInvalid(true);
            return;
        }
        try {
            const resp = await AccountAPI.getProfile(token);
            if (resp.status) {
                dispatch(userActions.updateUserInfo({ ...resp.data, token }));
                setIsReady(true);
            } else {
                setIsInvalid(true);
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            setIsInvalid(true);
        }
    };

    useEffect(() => {
        if (sso) {
            getProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sso]);

    useEffect(() => {
        if (isInvalid) {
            dispatch(userActions.logoutUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvalid]);

    return { isSSOReady: isReady, isSSOInvalid: isInvalid };
};

export default useSSO;
