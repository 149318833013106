import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './styles.module.scss';
import { animated, useTransition, config } from 'react-spring';
import classnames from 'classnames';

export type AlignmentType = 'left' | 'right';

interface Props {
    className?: string;
    // FIXME should avoid passing fix styles from parent. y position can be calculated from sum up parent(s) 'top' value.
    position: { y: number };
    alignment?: AlignmentType;
    visible?: boolean;
    onExit?: () => void;
}

const DropdownModal: React.FC<Props> = (props) => {
    const [attached, setAttached] = useState(false);

    const [visible, setVisible] = useState(false);

    const divRef = useRef<HTMLDivElement>(null);

    const transitions = useTransition(props.visible, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.stiff,
    });

    const listenOnClick = useCallback((e: any) => {
        if (!divRef.current?.contains(e.target) && props.onExit) {
            props.onExit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.visible && !attached) {
            document.addEventListener('click', listenOnClick);
            setAttached(true);
            setTimeout(() => setVisible(true), 400);
        } else if (!props.visible && attached) {
            setAttached(false);
            document.removeEventListener('click', listenOnClick);
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    const contentClass = classnames(styles.content, {
        [styles.visible]: visible,
    });

    return (
        <div
            className={classnames(styles.container, styles[props.alignment === 'left' ? 'alignLeft' : 'alignRight'], props.className)}
            style={{
                top: props.position.y,
            }}
            ref={divRef}
        >
            {transitions(
                (trans, item) =>
                    item && (
                        <animated.div className={contentClass} style={trans}>
                            {props.children}
                        </animated.div>
                    )
            )}
        </div>
    );
};

DropdownModal.defaultProps = {
    className: '',
    visible: false,
    alignment: 'left',
};

export default DropdownModal;
