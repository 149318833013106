import React from 'react';
import Head from 'next/head';
import Language from 'src/config/Language';
import RoutePaths from 'src/config/RoutePaths';
import { useTranslation } from 'next-i18next';
import useRouter from 'src/shared/hooks/useRouter';
import MetaData from 'src/config/MetaData';
import moment from 'moment';
import ArticlePostModel from 'src/types/article/ArticlePostModel';

// eslint-disable-next-line prefer-destructuring
const BASE_URL = process.env.BASE_URL;

const defaultImagePath = process.env.BASE_URL + '/static/images/meta_square.jpg';

const homeInfo = MetaData[RoutePaths.HOME];

const generateLink = (path, lang) => {
    let link = '';
    if (path === '/home' || path === RoutePaths.HOME) {
        link = `${process.env.BASE_URL}/${lang.path}`;
    } else {
        link = `${process.env.BASE_URL}/${lang.path}${path}`;
    }
    return link;
};

const formatDate = (date, format = 'yyyy-MM-DD HH:MM') => moment(date).format(format);

interface MetaField {
    en: string;
    tc: string;
    sc: string;
}

export interface PageMetaHeadProps {
    title?: MetaField | string;
    description?: MetaField | string;
    keywords?: MetaField | string;
    coverImage?: string;
    type?: 'website' | 'article';
    url?: string;
    customMetaArr?: Array<
        { key: string; name: string; content: string; property?: undefined } | { key: string; property: string; content: any; name?: undefined }
    >;
    article?: ArticlePostModel;
    isMarketingPage?: boolean;
}

const PageMetaHead: React.FC<PageMetaHeadProps> = (props) => {
    const { i18n } = useTranslation();
    const router = useRouter();
    let title = '';
    let description = '';
    let keywords = '';
    const coverImage = props.coverImage ? props.coverImage : defaultImagePath;

    const lang = Language[i18n.language].metaLang || i18n.language;

    if (props.title) {
        title = typeof props.title === 'string' ? props.title : props.title[lang];
    } else {
        title = homeInfo.title[lang];
    }

    if (props.description) {
        description = typeof props.description === 'string' ? props.description : props.description[lang];
    } else {
        description = homeInfo.description[lang];
    }

    if (props.keywords) {
        keywords = typeof props.keywords === 'string' ? props.keywords : props.keywords[lang];
    } else {
        keywords = homeInfo.keywords[lang];
    }

    const defaultMeta = [
        { key: 'og:type', property: 'og:type', content: props.type },
        // FIXME Language[lang] should not be undefined
        { key: 'og:locale', property: 'og:locale', content: Language[lang] ? Language[lang].locale : Language.en.locale },
        { key: 'og:image', property: 'og:image', content: coverImage },
    ];

    let fullMeta: any[] = [...defaultMeta];

    fullMeta.push({ key: 'og:site_name', property: 'og:site_name', content: title });
    fullMeta.push({ key: 'og:title', property: 'og:title', content: title });
    fullMeta.push({ key: 'description', name: 'description', content: description });
    fullMeta.push({ key: 'og:description', property: 'og:description', content: description });
    fullMeta.push({ key: 'keywords', name: 'keywords', content: keywords });
    fullMeta.push({ key: 'og:keywords', property: 'og:keywords', content: keywords });

    if (props.url) {
        fullMeta.push({ key: 'og:url', property: 'og:url', content: props.url });
    }

    if (process.env.HIDE_FROM_SEARCH_ENGINES) {
        fullMeta.push({ key: 'robots', name: 'robots', content: 'noindex' });
    }

    fullMeta = fullMeta.concat(props.customMetaArr);

    return (
        <Head>
            <title>{title}</title>
            <link rel="canonical" href={generateLink(router.asPath, Language.en)} />
            <meta name="facebook-domain-verification" content="3fo3insbr1isvt9pt8gohu6i1to7pa" />
            {!router.isMobile && <meta name="viewport" content="width=device-width, initial-scale=1" />}
            {router.isMobile && <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />}
            {Object.values(Language)
                .filter((language) => language.hide !== true)
                .map((language) => (
                    <link key={language.locale} rel="alternate" hrefLang={language.locale} href={generateLink(router.asPath, language)} />
                ))}
            {fullMeta.map((item) => (
                <meta key={item.key} {...item} />
            ))}
            {props.article && (
                // eslint-disable-next-line react/self-closing-comp
                <script
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'NewsArticle',
                            headline: props.article.title,
                            name: props.article.title,
                            image: [props.article.coverImage],
                            author: {
                                '@type': 'NewsMediaOrganization',
                                name: homeInfo.title[lang],
                                logo: {
                                    '@type': 'ImageObject',
                                    url: defaultImagePath,
                                },
                                // noBylinesPolicy: PRIVACY_POLICY,
                            },
                            editor: props.article.author,
                            genre: '',
                            keywords: props.keywords,
                            publisher: {
                                '@type': 'Organization',
                                name: homeInfo.title[lang],
                                url: BASE_URL,
                                logo: {
                                    '@type': 'ImageObject',
                                    url: defaultImagePath,
                                },
                            },
                            url: props.url,
                            datePublished: formatDate(props.article.publishedAt),
                            dateCreated: formatDate(props.article.publishedAt),
                            dateModified: formatDate(props.article.updatedAt),
                            description: props.article.excerpt,
                            alternativeHeadline: props.article.excerpt,
                            mainEntityOfPage: props.url,
                        }),
                    }}
                />
            )}
        </Head>
    );
};

PageMetaHead.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    customMetaArr: [],
    // eslint-disable-next-line react/default-props-match-prop-types
    type: 'website',
};

export default PageMetaHead;
