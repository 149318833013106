/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import Link from 'src/shared/components/Link';

export interface DropdownItemProps {
    className?: string;
    linkClassName?: string;
    path?: string;
    isShallow?: boolean;
    text: string;
    width: string | number;
    selected?: boolean;
    onClick?: (e: any) => void;
}

const DropdownItem: React.FC<DropdownItemProps> = (props) => {
    const linkClassName = classnames(styles.link, props.linkClassName);

    return (
        <div className={`${styles.container} ${props.className} ${props.selected && styles.current}`} style={{ width: props.width }}>
            {props.path && (
                <Link href={props.path} shallow={props.isShallow}>
                    <a className={linkClassName} onClick={props.onClick}>
                        {props.text}
                    </a>
                </Link>
            )}
            {!props.path && (
                <a className={linkClassName} onClick={props.onClick}>
                    {props.text}
                </a>
            )}
        </div>
    );
};

export default DropdownItem;
