import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import ExpireService from 'src/shared/services/ExpireService';
import globalActions from 'src/shared/store/global/actions';
import userActions from 'src/shared/store/user/actions';
import { useTranslation } from 'next-i18next';
import useInterval from 'src/shared/hooks/useInterval';

const oneMinute = 1000 * 60;
const timeout = oneMinute * (ExpireService.getExpireMinutes() as number);

const warningTimeout = oneMinute;

const usePageIdle = (memberInfo: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isIdle, setIsIdle] = useState(false);
    const [isWarning, setIsWarning] = useState(false);

    const handleOnIdle = () => {
        setIsIdle(true);
        setIsWarning(false);
        dispatch(userActions.logoutUser(true));
        dispatch(globalActions.showAlertDialog({ title: t('idle_timeout_logout') }));
    };

    const handleOnAction = () => {
        if (ExpireService.checkIsExpire(memberInfo.expireDate)) {
            dispatch(userActions.updateUserExpireDate(ExpireService.getExpireDate(), false));
        }
    };

    const { getRemainingTime } = useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
    });

    useInterval(
        () => {
            if (getRemainingTime() < warningTimeout && !isWarning) {
                setIsWarning(true);
            } else if (getRemainingTime() > warningTimeout && isWarning) {
                setIsWarning(false);
            }
            if (ExpireService.checkIsExpire(memberInfo.profileExpireDate) && !memberInfo.fetching) {
                dispatch(userActions.fetchAndUpdateUserInfo());
            }
        },
        !isIdle ? 1000 : null
    );

    useEffect(() => {
        if (isWarning) {
            dispatch(globalActions.showExpireDialog({ timeout: warningTimeout }));
        } else {
            dispatch(globalActions.dismissExpireDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWarning]);

    useEffect(() => {
        if (memberInfo && memberInfo.expireDate && ExpireService.checkIsExpire(memberInfo.expireDate)) {
            dispatch(userActions.logoutUser(true));
            dispatch(
                globalActions.showAlertDialog({
                    title: t('idle_timeout_logout'),
                })
            );
        }
        const closeTab = () => {
            dispatch(userActions.updateUserExpireDate(ExpireService.getExpireDate(), false));
        };
        window.addEventListener('beforeunload', closeTab);
        return () => {
            window.removeEventListener('beforeunload', closeTab);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default usePageIdle;
