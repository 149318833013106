import moment from 'moment';

const getExpireMinutes = () => process.env.TOKEN_EXPIRE_MINUTES || 30;

const getExpireDate = () => moment().add(getExpireMinutes(), 'minutes').unix();

const getProfileExpireDate = () => moment().add(1, 'days').unix();

const checkIsExpire = (date) => moment().unix() > date;

const ExpireService = {
    getExpireMinutes,
    getExpireDate,
    getProfileExpireDate,
    checkIsExpire,
};

export default ExpireService;
