import APIErrorCodes from 'src/shared/config/APIErrorCodes';
import _ from 'lodash';

const getError = (resp = { type: '', message: '', error: { type: '', code: 0 } }, showCode = false) => {
    const { message, error } = resp;
    const type = error?.type;
    const code = error ? error.code || 0 : 0;
    const defaultError = {
        code,
        title: 'Error Occurs',
        message: 'Network Error. Please try again.',
        showDialog: true,
    };
    let result: any = APIErrorCodes.find((i) => i.code === code.toString()) || defaultError;

    // display message if back-end provided.
    if (type) {
        result = {
            ...result,
            type,
        };
    }
    if (message) {
        result.message = message;
    }

    if (showCode) {
        result.message = `${result.message} [Error code: ${code}]`;
    }

    return result;
};

const getAPIError = (
    errorResponse:
        | { response: { type: string; message: string; data: { error: { type: string; code: number; extra: { message: string; type: string } } } } }
        | any,
    showCode = false
) => {
    const error = _.get(errorResponse, 'response.data.error') || {};
    const message = _.get(error, 'extra.message');
    const { type } = error;
    const code = error.code || 0;

    const result = {
        code,
        title: 'Error Occurs',
        message: 'Network Error. Please try again.',
        showDialog: true,
        ...(type && { type }),
        ...(message && { message }),
        ..._.find(APIErrorCodes, { code: code.toString() }),
    };

    if (showCode) {
        result.message = `${result.message} [Error code: ${code}]`;
    }

    return result;
};

const ErrorService = {
    getError,
    getAPIError,
};

export default ErrorService;
