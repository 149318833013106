import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

class HeaderTitle extends React.Component {
    render() {
        const { title } = this.props;
        return (
            <div className={styles.titleContainer}>
                <span className={styles.headerTitle}>{title}</span>
            </div>
        );
    }
}

HeaderTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default HeaderTitle;
