import RoutePaths from 'src/config/RoutePaths';

const MetaData = {
    [RoutePaths.HOME]: {
        title: {
            en: 'Altive | First-class Private Alternative Investment Platform',
            tc: 'Altive 安投 | 專業投資者的私人專屬另類投資平台',
            sc: 'Altive 安投 | 专业投资者的私人专属另类投资平台',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },

        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金',
        },
    },
    [RoutePaths.HOW_IT_WORKS]: {
        title: { en: 'How It Works | Altive', tc: '運作流程 | Altive 安投', sc: '运作流程 | Altive 安投' },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en: 'Altive account, how to set up Altive account, digital investment experience, research on alternative strategies',
            tc: 'Altive安投帳戶, 如何建立Altive安投帳戶, 數碼投資體驗, 另類投資策略研究報告, 另類投資策略資訊',
            sc: 'Altive安投帐户, 如何建立Altive安投帐户, 数码投资体验, 另类投资策略研究报告, 另类投资策略资讯',
        },
    },
    [RoutePaths.WHY_ALTIVE]: {
        title: { en: 'About | Altive', tc: '關於我們 | Altive 安投', sc: '关于我们 | Altive 安投' },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en: 'Altive background, What is Altive, What Altive offers,  Dedicated deal origination team, alternative investment opportunities',
            tc: '關於Altive安投, Altive安投是什麼, Altive安投提供什麼服務, 專業貼身的投資團隊, 另類投資機會',
            sc: '关于Altive安投, Altive安投是什么, Altive安投提供什么服务, 专业贴身的投资团队, 另类投资机会',
        },
    },
    [RoutePaths.PRIVATE_MARKET]: {
        title: { en: 'Why Private Market | Altive', tc: '認識私募市場 | Altive 安投', sc: '认识私募市场 | Altive 安投' },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en: 'Altive background, What is Altive, What Altive offers,  Dedicated deal origination team, alternative investment opportunities',
            tc: '關於Altive安投, Altive安投是什麼, Altive安投提供什麼服務, 專業貼身的投資團隊, 另類投資機會',
            sc: '关于Altive安投, Altive安投是什么, Altive安投提供什么服务, 专业贴身的投资团队, 另类投资机会',
        },
    },
    [RoutePaths.INSIGHTS]: {
        title: {
            en: 'Insights | Altive',
            tc: '市場觀點 | Altive 安投 ',
            sc: '市场观点 | Altive 安投',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.NEWS]: {
        title: {
            en: 'Media | Altive',
            tc: '媒體報導 | Altive 安投',
            sc: '媒体报导 | Altive 安投',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.EVENTS]: {
        title: {
            en: 'Events | Altive',
            tc: '活動 | Altive 安投',
            sc: '活动| Altive 安投',
        },
        description: {
            en: 'Past events and upcoming events. Altive provides professional investors access to alternative investment strategies.',
            tc: '過去和將來活動。安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '过去和将来活动。安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.NEWS_DETAIL]: {
        title: {
            en: 'Altive | Private Market & Alternative Investment News and Insights',
            tc: 'Altive 安投 | 私人市場、另類投資市場新聞資訊',
            sc: 'Altive 安投 | 私人市场及另类投资市场新闻资讯',
        },
        description: {
            en: 'Altive | Private Market & Alternative Investment News and Insights',
            tc: 'Altive 安投 | 私人市場、另類投資市場新聞資訊',
            sc: 'Altive 安投 | 私人市场及另类投资市场新闻资讯',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.LOGIN]: {
        title: {
            en: 'Login | Altive ',
            tc: '登入 | Altive 安投',
            sc: '登入 | Altive 安投',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.REGISTER]: {
        title: {
            en: 'Register | Altive  ',
            tc: '註冊 | Altive 安投 ',
            sc: '注册 | Altive 安投',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },
        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds, preipo, ipo, investment portfolio investment options',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金, 股票,投資',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金, 股票,投資',
        },
    },
    [RoutePaths.CONTACT_US]: {
        title: {
            en: 'Contact Us | Altive',
            tc: '聯絡我們 | Altive 安投 ',
            sc: '联络我们 | Altive 安投',
        },
        description: {
            en:
                'Altive provides professional investors access to alternative investment strategies, with a reduced minimum threshold at an affordable ticket size.',
            tc: '安投專為專業投資者提供最低門檻的另類投資機遇。',
            sc: '安投专为专业投资者提供最低门槛的另类投资机遇。',
        },

        keywords: {
            en:
                'Altive, alternative investment, institutional investment products, alternative strategies, alternative investment platform, private funds',
            tc: '安投, Altive安投, 另類投資, 機構投資產品, 另類投資策略, 另類投資平台, 私募基金',
            sc: '安投, Altive安投, 另类投资, 机构投资产品, 另类投资策略, 另类投资平台, 私募基金',
        },
    },
};

export default MetaData;
