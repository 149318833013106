import constants from 'src/shared/store/user/constants';
import common from 'src/shared/utils/common';
import AccountAPI from 'src/shared/services/api/AccountAPI';
import ExpireService from 'src/shared/services/ExpireService';

const setUserInfo = (params, store = true) => {
    const expireDate = ExpireService.getExpireDate();
    const profileExpireDate = ExpireService.getProfileExpireDate();
    return {
        type: store ? constants.SET_USER_INFO : constants.SET_TEMP_USER_INFO,
        payload: { expireDate, profileExpireDate, ...params },
    };
};

const updateUserInfo = (params) => async (dispatch, getState) => {
    try {
        const rootState = getState();
        dispatch(setUserInfo(params, common.isCheckedRememberMe(rootState)));
        return true;
    } catch (e) {
        return e;
    }
};

const clearUserInfo = (store = true, isSessionTimedOut) => {
    return {
        type: store ? constants.CLEAR_USER_INFO : constants.CLEAR_TEMP_USER_INFO,
        payload: { isSessionTimedOut },
    };
};

const updateUserExpireDate = (num = 0, store = true) => ({
    type: store ? constants.UPDATE_USER_EXPIRE_DATE : constants.UPDATE_TEMP_USER_EXPIRE_DATE,
    payload: num,
});

const logoutUser = (isSessionTimedOut = false) => async (dispatch, getState) => {
    try {
        const rootState = getState();
        await dispatch(clearUserInfo(common.isCheckedRememberMe(rootState), isSessionTimedOut));
        return true;
    } catch (e) {
        return e;
    }
};

const fetchAndUpdateUserInfo = () => async (dispatch, getState) => {
    try {
        const rootState = getState();
        const { memberInfo } = common.processMemberInfo(rootState);
        if (!memberInfo.fetching) {
            await dispatch({ type: constants.FETCHING_USER_INFO, payload: { fetching: true } });
            const resp = await AccountAPI.getProfile(memberInfo.token);
            if (resp.status) {
                await dispatch(updateUserInfo(resp.data));
            }
            await dispatch({ type: constants.FETCHING_USER_INFO, payload: { fetching: false } });
        }
        return true;
    } catch (e) {
        return e;
    }
};

const loginUser = (auth, err) => {
    // TODO
    // eslint-disable-next-line no-console
    console.log({ auth, err });
    // return async (dispatch, getState) => {
    //     try {
    //         const resp = await loginUserAccount(auth, err);
    //         if (resp.token) {
    //           const decodeToken = decode(resp.token);
    //           if (decodeToken.scopes.includes(config.USER_SCOPE)) {
    //               dispatch(setUserInfo({token: resp.token, ...decodeToken}));
    //               return decodeToken;
    //           } else {
    //               dispatch(globalActions.showAlertModal({text: "You don't have permission to access."}));
    //               return false;
    //           }
    //         }
    //     } catch (e) {
    //         return e;
    //     }
    // };
};

const userActions = {
    setUserInfo,
    clearUserInfo,
    updateUserExpireDate,
    loginUser,
    logoutUser,
    updateUserInfo,
    fetchAndUpdateUserInfo,
};

export default userActions;
