import CryptoJS from 'crypto-js';

const secret = process.env.AES_SECRET;
const iv = process.env.AES_IV;

export const encrypt = (word: string) => {
    const encJson = CryptoJS.AES.encrypt(word, secret, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
    return encData;
};

export const decrypt = (word: string) => {
    try {
        const decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(decData, secret, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
        return bytes;
    } catch (error) {
        console.log(error);
        return '';
    }
};
const ssoUtils = {
    encrypt,
    decrypt,
};

export default ssoUtils;
