const APIErrorCodes = [
    {
        code: '100101',
        title: 'Not Authenticated',
        message: 'Please login again',
        buttons: [
            {
                text: 'OK',
                action: 'logout',
            },
        ],
        showDialog: false,
    },
    {
        code: '100102',
        title: 'Not Authenticated',
        message: 'Token Expired. Please login again',
        buttons: [
            {
                text: 'OK',
                action: 'logout',
            },
        ],
        showDialog: true,
    },
    {
        code: '100111',
        title: 'Forbidden',
        message: "You don't have permission to access",
        showDialog: true,
    },
    {
        code: '100199',
        title: 'Error Occurs',
        message: 'Please try again later',
        showDialog: true,
    },
    {
        code: '100201',
        title: 'Error Occurs',
        message: 'Your information is incorrect',
        showDialog: true,
    },
    {
        code: '100202',
        title: 'Error Occurs',
        message: 'Please contact administrator',
        showDialog: true,
    },
    {
        code: '100211',
        title: 'Error Occurs',
        message: 'Please contact administrator',
        showDialog: true,
    },
    {
        code: '100299',
        title: 'Error Occurs',
        message: 'Please contact administrator',
        showDialog: true,
    },
    {
        code: '100988',
        title: 'Error Occurs',
        message: 'Please contact administrator',
        showDialog: true,
    },
    {
        code: '100999',
        title: 'Error Occurs',
        message: 'Please contact administrator [Error code: 100999]',
        showDialog: true,
    },
];

export default APIErrorCodes;
