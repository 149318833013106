import { useSelector } from 'react-redux';
import common from 'src/shared/utils/common';
import { RootState } from 'src/store/rootReducer';

const useMemberInfo = () => {
    const { memberInfo } = useSelector((state: RootState) => common.processMemberInfo(state));
    return memberInfo;
};

export default useMemberInfo;
