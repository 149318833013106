const PENDING = 'pending';
const APPROVED = 'approved';
const REJECTED = 'rejected';

export const REVIEW_STATUS = [PENDING, REJECTED];

export default {
    PENDING,
    APPROVED,
    REJECTED,
};
