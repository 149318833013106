import React, { useState } from 'react';
import DropdownItem from './components/DropdownItem';
import DropdownModal, { AlignmentType } from './components/DropdownModal';
import styles from './styles.module.scss';

import useMeasure from 'src/shared/hooks/useMeasure';
import { useHover } from 'react-use-gesture';

interface ItemProps {
    text: string;
    path?: string;
    isShallow?: boolean;
    onClick?: () => void;
}
interface Props {
    className?: string;
    menuClassName?: string;
    itemClassName?: string;
    linkClassName?: string;
    text?: string;
    type: 'black' | 'white' | 'transparent';
    alignment?: AlignmentType;
    itemWidth: string | string;
    selected?: string;
    items: Array<ItemProps>;
    position?: { y: number };
    toggleInHover?: boolean;
    renderButton: () => React.ReactNode;
}

const Dropdown: React.FC<Props> = (props) => {
    const [visible, setVisible] = useState(false);
    const [bind, bounds] = useMeasure();

    const openDropdown = () => {
        setVisible(true);
    };

    const closeDropdown = () => {
        setVisible(false);
    };

    const clickItem = (e: any, item: ItemProps) => {
        e.stopPropagation();
        if (item.onClick) {
            item.onClick();
        }

        closeDropdown();
    };

    let timeToHide = null;

    const bindHover = useHover((state) => {
        if (props.toggleInHover) {
            if (state.hovering) {
                clearTimeout(timeToHide);
                setVisible(true);
            } else {
                timeToHide = setTimeout(() => setVisible(false), 500);
            }
        }
    });

    return (
        <button
            {...bind}
            {...bindHover()}
            className={`${styles.dropdown} ${props.className} ${props.type === 'black' ? styles.black : ''}`}
            onClick={openDropdown}
        >
            {props.renderButton()}

            <DropdownModal
                className={props.menuClassName}
                alignment={props.alignment}
                position={{ y: bounds.height + props.position?.y }}
                visible={visible}
                onExit={closeDropdown}
            >
                {props.items.map((x, i) => (
                    <DropdownItem
                        key={i}
                        selected={x.text === props.selected}
                        className={props.itemClassName}
                        linkClassName={props.linkClassName}
                        text={x.text}
                        path={x.path}
                        isShallow={x.isShallow}
                        width={props.itemWidth}
                        onClick={(e) => clickItem(e, x)}
                    />
                ))}
            </DropdownModal>
        </button>
    );
};

Dropdown.defaultProps = {
    className: '',
    menuClassName: '',
    itemClassName: '',
    linkClassName: '',
    alignment: 'left',
    text: '',
    toggleInHover: false,
    position: { y: 34 },
};

export default Dropdown;
