import React from 'react';

import RoutePaths from 'src/config/RoutePaths';
import UserStatus from 'src/shared/config/UserStatus';
import Image from 'src/shared/components/Image';
import Link from 'src/shared/components/Link';
import HeaderTitle from 'src/shared/components/HeaderTitle';

import styles from './styles.module.scss';

type LogoProps = {
    className?: string;
    isLink?: boolean;
    memberInfo?: any;
    theme?: string;
    title?: string;
    onClick?: () => void;
};

const Logo = ({ className, isLink, memberInfo, theme, title, onClick }: LogoProps) => {
    const src = theme === 'white' ? '/static/images/logo_light.svg' : '/static/images/logo_dark.svg';

    return (
        <div className={`${styles.container} ${className}`}>
            {!isLink && (
                <div className={styles.brandContainer}>
                    <Image className={styles.logo} src={src} />
                </div>
            )}
            {isLink && (
                <Link href={memberInfo.token && memberInfo.status === UserStatus.APPROVED ? RoutePaths.INVESTMENT : RoutePaths.HOME}>
                    <a className={styles.brandContainer} onClick={onClick} aria-hidden="true">
                        <Image className={styles.logo} src={src} />
                    </a>
                </Link>
            )}
            {title && <HeaderTitle title={title} />}
        </div>
    );
};

Logo.defaultProps = {
    className: '',
    isLink: true,
    memberInfo: {},
    title: '',
    theme: 'white',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
};

export default Logo;
