import RequestService from 'src/shared/services/RequestService';
import common from 'src/shared/utils/common';
import localeUtils from 'src/shared/utils/localeUtils';
import gaUtils from 'src/utils/gaUtils';

const basePath = process.env.BASE_API_URL;

export const DEFAULT_OTP_CHANNEL = 'sms';

const getProfile = (token, id = '_me') => {
    const url = `${basePath}/accounts/${id}`;
    const config = {
        headers: { Authorization: `Bearer ${token}`, 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.get(url, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const login = (data) => {
    const url = `${basePath}/actions/login`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const register = async (params, query = {}) => {
    const url = `${basePath}/actions/register`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    const data = {
        recaptcha: params.recaptcha,
        account: {
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            password: params.password,
            phoneCountryCode: params.countryCode,
            phoneNumber: params.phoneNumber,
            accountType: params.type,
            isProfessional: params.isProfessional || false,
            agreeNdaAndPrivacy: params.agreeNdaAndPrivacy || false,
            receiveUpdates: params.receiveUpdates || false,
            preferredLocale: params.preferredLocale,
        },
        otp: {
            otpCode: params.passcode,
            otpChannel: 'sms',
        },
    };
    if (params.type === 'investor') {
        const gaInfo = await gaUtils.getGaInfo();

        data.account.investor = {
            fieldOfWork: params.fieldOfWork,
            position: params.position,
            sourceOfWealth: params.sourceOfWealth,
            netWorth: params.netWorth,
            hearAboutUs: params.hearAboutUs,
            referralEmail: params.referralEmail,
            clientId: gaInfo.clientId,
            referrer: gaInfo.referrer,
            campaign: query.utm_campaign,
            source: query.utm_source,
            medium: query.utm_medium,
        };
    }
    if (params.type === 'advisor') {
        data.account.advisor = {
            organization: params.organization,
            title: params.title,
        };
    }
    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const requestOTP = (params, event = 'register', token) => {
    const url = `${basePath}/actions/otp`;
    const config = {
        headers: {
            'Accept-Language': localeUtils.getLocaleForServer(),
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
    };
    // @TODO "register", "login", "reset_pwd", "activation", "view_contract" enum
    let data = {
        recaptcha: params.recaptcha,
        account: {
            firstName: params.firstName || undefined,
            lastName: params.lastName || undefined,
            email: params.email || undefined,
            phoneCountryCode: params.countryCode,
            //  FIXME: phoneNumber is for member portal; mobile is for admin portal
            phoneNumber: params.phoneNumber || params.mobile,
        },
        otp: {
            otpChannel: DEFAULT_OTP_CHANNEL,
        },
        token: params.token || undefined,
        event,
    };

    if (event === 'view_contract') {
        data = {
            ...params,
            otp: {
                otpChannel: 'sms',
            },
            event,
        };
    }

    if (event === 'approve_distribution_payment') {
        data = {
            ...params,
            otp: {
                otpChannel: 'email',
            },
            event,
        };
        return RequestService.post(url, data, config)
            .then((res) => res.data)
            .catch((err) => {
                if (err.response && err.response.data) {
                    return err.response.data;
                }
                return false;
            });
    }

    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const verifyOTP = (params) => {
    const url = `${basePath}/actions/otp/verify`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    const data = {
        account: {
            phoneCountryCode: params.countryCode,
            phoneNumber: params.phoneNumber,
        },
        otp: {
            otpCode: params.passcode,
            otpChannel: 'sms',
        },
    };
    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const resendActivationEmail = (email) => {
    const url = `${basePath}/accounts/send-activate-email`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    const data = { email };

    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const resetPassword = (params) => {
    const url = `${basePath}/actions/reset_pwd`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    const data = {
        account: {
            email: params.email,
            phoneCountryCode: params.countryCode,
            phoneNumber: params.phoneNumber,
            password: params.password,
        },
        otp: {
            otpCode: params.passcode,
            otpChannel: 'sms',
        },
    };
    return RequestService.put(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const updateProfile = (data, token, id = '_me') => {
    const url = `${basePath}/accounts/${id}`;
    const config = {
        headers: { Authorization: `Bearer ${token}`, 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.put(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const getSubscribedFund = (token, params = {}) => {
    const url = `${basePath}/accounts/_me/fund_news_subscriptions`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        'Accept-Language': localeUtils.getLocaleForServer(),
        params,
    };
    return RequestService.get(url, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const subscribeFund = (token, id) => {
    const url = `${basePath}/accounts/_me/fund_news_subscriptions/${id}`;
    const config = {
        headers: { Authorization: `Bearer ${token}`, 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.put(url, {}, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const activateAccount = (params) => {
    const url = `${basePath}/actions/activate`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    const data = {
        recaptcha: params.recaptcha,
        token: params.token,
        account: {
            password: params.password,
            isProfessional: params.isProfessional,
            agreeNdaAndPrivacy: params.agreeNdaAndPrivacy,
            receiveUpdates: params.receiveUpdates,
        },
        otp: {
            otpCode: params.passcode,
            otpChannel: 'sms',
        },
    };
    return RequestService.put(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const contractAccessToken = (params) => {
    const url = `${basePath}/actions/contract/accesstoken`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    // const { passcode, ...others } = params;
    // const data = {
    //     otp: {
    //         otpCode: passcode,
    //         otpChannel: 'sms',
    //     },
    //     ...others,
    // };
    return RequestService.post(url, params, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const getAllocationContract = (data) => {
    const url = `${basePath}/actions/contract/view`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const finishAllocationContract = (data) => {
    const url = `${basePath}/actions/contract/finish`;
    const config = {
        headers: { 'Accept-Language': localeUtils.getLocaleForServer() },
    };
    return RequestService.post(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const subscribeNewsletter = (params, token = '') => {
    const url = `${basePath}/actions/newsletter/subscriptions`;
    const config = token
        ? {
              headers: { Authorization: `Bearer ${token}` },
          }
        : {};
    return RequestService.post(url, params, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const contactSales = (params, token = '') => {
    const url = `${basePath}/actions/contact_sales`;
    const config = token
        ? {
              headers: { Authorization: `Bearer ${token}` },
          }
        : {};

    return RequestService.post(url, params, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const subscribeWithInterest = (fundId, amount, token) => {
    const config = token
        ? {
              headers: { Authorization: `Bearer ${token}` },
          }
        : {};

    const url = `${basePath}/accounts/_me/subscribe-with-interest`;
    const data = {
        fundId,
        interestedAmount: amount,
    };
    return RequestService.put(url, data, config)
        .then((res) => res.data)
        .catch((err) => {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return false;
        });
};

const AccountAPI = {
    getProfile,
    login,
    register,
    requestOTP,
    verifyOTP,
    resendActivationEmail,
    resetPassword,
    updateProfile,
    getSubscribedFund,
    subscribeFund,
    activateAccount,
    contractAccessToken,
    getAllocationContract,
    finishAllocationContract,
    subscribeNewsletter,
    contactSales,
    subscribeWithInterest,
};

export default AccountAPI;
